<template lang="pug">
//- t-card(Zbody-height="300px" v-on="$listeners" Zscroll="scroll")
t-window(v-bind="$attrs" v-on="$listeners" min-height="300px")
  template(slot="header") Оплата #120

  v-card-title.justify-center.headline.font-weight-bold Просканируйте QR

  t-list.mb-5
    v-img(src="/demo_kaspi_qr.png")

  template(slot="footer")
      Basket(@click="$emit('route','check')" label="Оплатить" no-icon no-menu emptyDisabled no-empty)
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

const lastScrollPosition = 0

export default {
  components: {
    Basket: () => import('../Basket.vue'),
  },
  props: {
  },

  data: () => ({
  }),
  computed: {
    ...mapGetters({
    }),
  },
  methods: {
    ...mapActions({
    }),
  },
}
</script>
<style lang="stylus">

// .theme--dark.v-divider
//     border-color: rgba(150,150,150,0.12) !important;

// .v-list-group .v-list-group__header .v-list-item__icon.v-list-group__header__append-icon
//   min-width auto !important

// .v-application--is-ltr .v-list-item__action:last-of-type:not(:only-child), .v-application--is-ltr .v-list-item__avatar:last-of-type:not(:only-child), .v-application--is-ltr .v-list-item__icon:last-of-type:not(:only-child)
//   margin-left: 8px

</style>
